import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const items = [
  {
    headline: "I design",
    content: "attractive, user-friendly interfaces for websites & mobile apps",
    image: (
      <StaticImage src="../images/design.png" alt="Design" placeholder="none" />
    ),
  },
  {
    headline: "I code",
    content: "clear, well-organised, semantic front-end markup and CSS",
    image: (
      <StaticImage src="../images/code.png" alt="Code" placeholder="none" />
    ),
  },
  {
    headline: "I deliver",
    content: "mockups, code & other deliverables on time and budget",
    image: (
      <StaticImage
        src="../images/deliver.png"
        alt="Deliver"
        placeholder="none"
      />
    ),
  },
];

const Header = () => (
  <div className="container mx-auto px-4 md:flex justify-evenly pt-6 md:pt-20">
    {items.map((item, index) => (
      <div
        className="flex items-center mb-6 md:mb-20 md:block md:text-center text-gray-600"
        key={index}
      >
        <div className="w-20 h-20 mr-4 md:mx-auto md:w-40 md:h-40 md:mb-3">
          {item.image}
        </div>
        <h2 className="md:text-lg md:max-w-xs">
          <strong className="block md:text-3xl md:mb-2 text-gray-800">
            {item.headline}
          </strong>
          <span className="font-light">{item.content}</span>
        </h2>
      </div>
    ))}
  </div>
);

export default Header;
