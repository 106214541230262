import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Navbar = ({ toggleModal }) => (
  <nav className="fixed top-0 left-0 right-0 bg-gray-100 z-10">
    <div className="flex justify-between h-16">
      <div className="flex items-center flex-grow">
        <StaticImage
          className="h-full mr-2"
          src="../images/icon.png"
          alt="Jack Herbert"
          placeholder="none"
          height={64}
        />
        <h1 className="md:text-lg">
          <span className="font-bold text-gray-500 mr-3">Jack Herbert</span>
          <span className="text-gray-400 font-light block md:inline">
            Freelance UI Designer
          </span>
        </h1>
      </div>

      <button
        type="button"
        className="text-white bg-blue-400 hover:bg-blue-500 px-5 flex items-center font-medium transition focus:outline-none"
        onClick={toggleModal}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-5 w-5 md:mr-3"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
        <span className="hidden md:block text-lg">Contact</span>
      </button>
    </div>
  </nav>
);

export default Navbar;
