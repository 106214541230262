import * as React from "react";

import react from "../images/logos/react.svg";
import photoshop from "../images/logos/photoshop.svg";
import sketch from "../images/logos/sketch.svg";
import bootstrap from "../images/logos/bootstrap.svg";
import sass from "../images/logos/sass.svg";
import tailwind from "../images/logos/tailwind.svg";

const logos = [
  {
    name: "React",
    image: react,
  },
  {
    name: "Sketch",
    image: sketch,
  },
  {
    name: "Photoshop",
    image: photoshop,
  },
  {
    name: "Tailwind CSS",
    image: tailwind,
  },
  {
    name: "Bootstrap",
    image: bootstrap,
  },
  {
    name: "Sass",
    image: sass,
  },
];

const Logos = () => (
  <div className="bg-gray-100 py-5">
    <div className="container mx-auto flex flex-wrap justify-evenly items-center">
      {logos.map((item, index) => (
        <div
          className="m-5 filter-grayscale opacity-30 hover:opacity-100 transition transform-gpu hover:-translate-y-1 hover:filter-none flex-1"
          key={index}
        >
          <img
            className="h-10 md:h-16 block mx-auto"
            src={item.image}
            alt={item.name}
          />
          <div className="text-center text-xs font-medium text-gray-700 mt-3">
            {item.name}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Logos;
