import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const variants = {
  iphone: {
    background: (
      <StaticImage
        src="../images/iphone5@2x.png"
        alt="phone"
        placeholder="none"
      />
    ),
    right: "8.8%",
    top: "7.85%",
    width: "80.4%",
  },
  ipad: {
    background: (
      <StaticImage
        src="../images/ipad@2x.png"
        alt="tablet"
        placeholder="none"
      />
    ),
    right: "8.9%",
    top: "7.8%",
    width: "83.5%",
  },
  desktop: {
    background: (
      <StaticImage
        src="../images/imac@2x.png"
        alt="Desktop"
        placeholder="none"
      />
    ),
    right: "5%",
    top: "5%",
    width: "90%",
  },
};

const Mockup = ({ project }) => {
  const { type, screens } = project;
  const [count, setCount] = React.useState(0);
  React.useEffect(() => {
    const timer = setInterval(() => setCount((v) => v + 1), 10000);
    return () => clearInterval(timer);
  }, []);
  const current = !screens ? 0 : count % screens.length;
  const { background, right, top, width } = variants[type];

  return (
    <div className="relative">
      {background}
      {screens &&
        screens.map((screen, index) => (
          <div
            key={index}
            className={`absolute transition duration-1000 ${
              index === current ? "opacity-1" : "opacity-0"
            }`}
            style={{
              right,
              top,
              width,
            }}
          >
            {screen}
          </div>
        ))}
    </div>
  );
};

export default Mockup;
