import * as React from "react";
import { Helmet } from "react-helmet";

import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Logos from "../components/Logos";
import Projects from "../components/Projects";
import Contact from "../components/Contact";

const IndexPage = () => {
  const [showModal, setShowModal] = React.useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <main className="pt-16 md:pt-12">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Freelance UI Designer in Cambridge, UK</title>
        <meta
          name="description"
          content="I design attractive, user-friendly interfaces for websites and mobile apps."
        />
        <link rel="canonical" href="https://jackherbert.com" />
      </Helmet>
      <Navbar toggleModal={toggleModal} />
      <Header />
      <Logos />
      <Projects />
      <Contact showModal={showModal} toggleModal={toggleModal} />
    </main>
  );
};

export default IndexPage;
