import * as React from "react";

const Contact = ({ showModal, toggleModal }) => (
  <div
    className={`fixed z-20 inset-0 overflow-y-auto flex items-center justify-center min-h-screen ${
      !showModal ? "hidden" : ""
    }`}
  >
    <div
      className={`fixed inset-0 transition-opacity bg-black ${
        showModal ? "opacity-50" : "opacity-0"
      }`}
      aria-hidden="true"
      onClick={toggleModal}
    ></div>

    <div
      className={`block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all max-w-md overflow-hidden ${
        showModal ? "opacity-100 translate-y-0" : "opacity-0 translate-y-full"
      }`}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div className="py-4 px-7 border-solid border-b border-solid border-gray-200 bg-gray-100 flex justify-between">
        <h3 className="text-lg font-bold text-gray-600" id="modal-headline">
          Contact me
        </h3>
        <button
          type="button"
          className="block w-7 h-7 text-xl text-gray-400 hover:text-gray-600 focus:outline-none focus:ring rounded"
          onClick={toggleModal}
        >
          &times;
        </button>
      </div>
      <div className="py-6 px-7">
        <a
          className="block mb-4 text-blue-500 font-bold hover:text-blue-600"
          href="mailto:jack@jackherbert.com"
        >
          jack@jackherbert.com
        </a>
        <p className="text-sm text-gray-600">
          My hourly rate is <strong>£50 ($75)</strong>. However, if you'd prefer
          a fixed quote for a project, we'll work something out.
        </p>
      </div>
    </div>
  </div>
);

export default Contact;
