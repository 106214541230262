import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const data = [
  {
    name: "Opp App",
    tags: ["iPhone Design"],
    description:
      "Opp allows organisations to communicate better with their employees and stakeholders, and gather more meaningful feedback. I provided a clean, professional design with splashes of colour to attract attention to important elements and functions.",
    link: {
      name: "App Store page",
      url: "https://itunes.apple.com/gb/app/opp-app/id932339793?mt=8",
    },
    type: "iphone",
    testimonial: {
      content: "We really like your design. Looks very professional.",
      author: "Bas van Kesteren, Co-Founder",
    },
    screens: [
      <StaticImage
        src="../images/screens/opp-splash.png"
        alt=""
        placeholder="none"
        layout="fullWidth"
      />,
      <StaticImage
        src="../images/screens/opp-conversation.png"
        alt=""
        placeholder="none"
        layout="fullWidth"
      />,
      <StaticImage
        src="../images/screens/opp-inbox.png"
        alt=""
        placeholder="none"
        layout="fullWidth"
      />,
      <StaticImage
        src="../images/screens/opp-question.png"
        alt=""
        placeholder="none"
        layout="fullWidth"
      />,
    ],
  },
  {
    name: "Infuse",
    tags: ["Responsive website design", "Front-end code"],
    description:
      "I've been working with the company that makes Infuse for a number of years, and they decided they wanted a new design for their popular media app. We came up with a clean, responsive design that looks great on desktop browsers and - more importantly for their target audience - mobile devices.",
    link: {
      name: "Infuse website",
      url: "http://firecore.com/infuse",
    },
    type: "desktop",
    testimonial: {
      content: "Keep on being awesome. :)",
      author: "James Abeler, Executive Director",
    },
    screens: [
      <StaticImage
        src="../images/screens/infuse@2x.png"
        alt=""
        placeholder="none"
      />,
      <StaticImage
        src="../images/screens/infuse-features@2x.png"
        alt=""
        placeholder="none"
      />,
    ],
  },
  {
    name: "Jepp",
    tags: ["iPhone app design", "Website design"],
    description:
      "Jepp is a cross-platform application that allows people to assess their existing health and make steps to improve and measure their progress. I helped them design a friendly, clean web application, and a complementary mobile version.",
    type: "iphone",
    testimonial: {
      content: "Great work! You have really clarified the process!",
      author: "Johannes Cullberg, Owner/CEO",
    },
    screens: [
      <StaticImage
        src="../images/screens/jepp-categories@2x.png"
        alt=""
        placeholder="none"
      />,
      <StaticImage
        src="../images/screens/jepp-compose@2x.png"
        alt=""
        placeholder="none"
      />,
      <StaticImage
        src="../images/screens/jepp-congrats@2x.png"
        alt=""
        placeholder="none"
      />,
      <StaticImage
        src="../images/screens/jepp-addjepp@2x.png"
        alt=""
        placeholder="none"
      />,
    ],
  },
  {
    name: "BookWhiz",
    tags: ["iPad app design", "Website design"],
    description:
      "Bookwhiz is an iPad app for kids and their parents to track their reading, and earn rewards. Because the target age group includes young children, we needed a friendly, colourful design that would appeal to them without putting off older ones.",
    type: "ipad",
    testimonial: {
      content: "Well done, Jack! The kids loved your initial set.",
      author: "Andra Davidson, Founder",
    },
    screens: [
      <StaticImage
        src="../images/screens/bookwhiz-library@2x.png"
        alt=""
        placeholder="none"
      />,
    ],
  },
  {
    name: "Tweepi",
    tags: ["Web app design", "Front-end code"],
    description:
      "Tweepi is a powerful suite of Twitter tools for companies and power users. They had outgrown their design, so we came up with an appealing responsive redesign that complemented in their existing branding. They also had me design and code an updated landing page design to better highlight Tweepi's features.",
    type: "desktop",
    link: {
      name: "Tweepi website",
      url: "http://tweepi.com/",
    },
    testimonial: {
      content:
        "Jack rocks! We needed a designer who understands CSS and Bootstrap, and Jack is that man! Amazingly fast responses. Awesome designs. All our questions had an immediate answer and fix. Just brilliant!",
      author: "Amer Kawar, Founder",
    },
    screens: [
      <StaticImage
        src="../images/screens/tweepi-homepage@2x.png"
        alt=""
        placeholder="none"
      />,
      <StaticImage
        src="../images/screens/tweepi-dashboard@2x.png"
        alt=""
        placeholder="none"
      />,
      <StaticImage
        src="../images/screens/tweepi-list@2x.png"
        alt=""
        placeholder="none"
      />,
    ],
  },
  {
    name: "Mission Control",
    tags: ["Android app design", "iPhone app design"],
    description:
      "Mission Control is an internal training app allowing companies to make sure their employees' internal training and knowledge is up-to-date. Because each company has its own training & testing needs – and these can vary considerably – they needed a flexible design to cope with a variety of training resources.",
    type: "iphone",
    link: {
      name: "App Store page",
      url:
        "https://itunes.apple.com/us/app/mission-control-training-app/id827119367?mt=8",
    },
    testimonial: {
      content: "Thank you! Looks great and appreciate the quick turnaround.",
      author: "Jim Bishop, Mission Control developer",
    },
    screens: [
      <StaticImage
        src="../images/screens/insideout-dashboard@2x.png"
        alt=""
        placeholder="none"
      />,
      <StaticImage
        src="../images/screens/insideout-lessons@2x.png"
        alt=""
        placeholder="none"
      />,
      <StaticImage
        src="../images/screens/insideout-certification@2x.png"
        alt=""
        placeholder="none"
      />,
      <StaticImage
        src="../images/screens/insideout-question@2x.png"
        alt=""
        placeholder="none"
      />,
    ],
  },
];

export default data;
