import * as React from "react";
import Mockup from "./Mockup";
import data from "./data";

const Projects = () => {
  return data.map((project, index) => {
    const { name, tags, description, testimonial, link } = project;
    const even = index % 2 === 0;
    return (
      <div
        key={name}
        className={`${
          even ? "bg-gray-700" : "bg-gray-800"
        } text-gray-400 py-12 md:py-24`}
      >
        <div className="container mx-auto px-4 grid md:grid-cols-2 gap-5 md:gap-10">
          <div className={`${even ? "md:order-last" : "md:order-first"}`}>
            <Mockup project={project} />
          </div>
          <div className="flex flex-col items-start">
            <h3 className="text-5xl font-thin text-white mb-5">{name}</h3>
            <div>
              {tags.map((tag, index) => (
                <span
                  className="inline-block text-sm font-medium tracking-wide bg-blue-400 text-white uppercase rounded px-3 py-1 mb-3 mr-3"
                  key={index}
                >
                  {tag}
                </span>
              ))}
            </div>
            <p className="mb-4 max-w-xl">{description}</p>
            {link && (
              <a
                href={link.url}
                className="inline-block rounded text-gray-400 border-gray-400 bg-gray-800 hover:bg-gray-900 hover:text-white hover:border-white px-5 py-3 font-bold transition border mb-6"
              >
                {link.name}
              </a>
            )}
            {testimonial && (
              <blockquote className="mt-auto">
                <p className="text-white font-medium">
                  &ldquo;{project.testimonial.content}&rdquo;
                </p>
                <footer>{project.testimonial.author}</footer>
              </blockquote>
            )}
          </div>
        </div>
      </div>
    );
  });
};

export default Projects;
